<template>
	<div class="bg-cls">
		<div class="main-title">
			<div class="big-title">多平台销售</div>
			<div class="small-title">—   飞飞鱼合作伙伴   —</div>
		</div>
		<!-- 伙伴logo -->
		<div class="partner-box">
			<div class="partner-list">
				<div class="partner-item" v-for="(item, index) in initData.partner" :key="index" @click="goWeb(item.turn_url)">
					<img class="partner-img" :src="item.img_id" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {
				partnerList: [], // 合作伙伴
			}
		},
		computed: {
			...mapState({
				initData: state => state.initData, //初始化数据
			})
		},
		mounted() {
		},
		methods: {
			// 跳转外部链接
			goWeb(url) {
				if (url) {
					if (url.indexOf('http') == -1) {
						url = 'https://' + url
					}
					window.open(url, '_blank');
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.bg-cls {
		padding: 3rem 0 0.5rem;
	}

	.partner-box {
		margin: 2rem auto 0;
		width: 95vw;
		.partner-list{
			display: flex;
			flex-wrap: wrap;
		}
		.partner-item{
			margin: 0 0.7rem 0.2rem 0.7rem;
		}
		
		.partner-img{
			height: 3rem;
			width: 6rem;
			object-fit:contain;
		}
	}
</style>
