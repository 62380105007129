import Vue from 'vue'
// 按需引入elementUI组件
import {
  Pagination,
  Message,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  MessageBox,
  Carousel,
  CarouselItem,
  Image
} from 'element-ui'

Vue.use(Image)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Carousel)
Vue.use(CarouselItem)

Vue.component(MessageBox.name, MessageBox)
Vue.component(Message.name, Message)
// 插件需要挂载到原型
Vue.prototype.$alert = MessageBox.alert
// message默认配置
Vue.prototype.$message = (options) => {
  return Message(Object.assign({
    offset: 300,
    center: true,
    type: 'success',
    duration: 1200,
  }, options))
}
