import Vue from "vue";
import Vuex from "vuex";

export const INIT_DATA = 'INIT_DATA' //初始化数据
export const PLATFORM = 'PLATFORM' // 当前设备信息
export const SAVEBANNERINFO = 'SAVEBANNERINFO' // banner列表

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		initData: {}, // 初始化数据
		platform: true, // true为PC端，false为移动端
		bannerInfo:{},
	},
	mutations: {
		[INIT_DATA](state, data) {
			state.initData = data
		},
		[PLATFORM](state, data) {
			state.platform = data
		},
		[SAVEBANNERINFO](state, data) {
			state.bannerInfo = data
		},
	},
	actions: {
		getPlatform({
			commit
		}) {
			// 获取设备信息
			var browser = {
				versions: function() {
					let u = navigator.userAgent;
					return { //移动终端浏览器版本信息 
						trident: u.indexOf('Trident') > -1, //IE内核
						presto: u.indexOf('Presto') > -1, //opera内核
						webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
						iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, //是否iPad  
						webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
						weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
						qq: u.match(/\sQQ/i) == " qq" //是否QQ
					};
				}(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
			
			
			console.log('执行')

			if (browser.versions.mobile || browser.versions.ios || browser.versions.android ||
				browser.versions.iPhone || browser.versions.iPad) { //h5
				commit('PLATFORM', false);
			} else {
				commit('PLATFORM', true);
			}
			
		}
	},
	modules: {},
});
