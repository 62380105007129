<template>
	<div class="header-box">
		<div class="head-max">
			<div class="logo">
				<img src="../assets/logo.png" alt="">
			</div>
			<div class="router-box">
				<div>
					<router-link :to="toPath(item.id)" v-for="item in navigation" :key="item.id">{{item.name}}</router-link>
				</div>
				<div class="active-line" :style="styleObj"></div>
			</div>
			<div class="login-box">
				<div class="btn active" @click="goWeb('http://us.entsku.com')">登录</div>
				<div class="btn">
					<router-link :to="{name:'Contact',params:{toClass:'.join-box'}}">注册</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "headerCom",
		data() {
			return {
				routerList: [
					{
						id:1,
						path:'/',
					},
					{
						id:4,
						path:'/platform',
					},
					{
						id:8,
						path:'/ipZone',
					},
					{
						id:7,
						path:'/productCore',
					},
					{
						id:5,
						path:'/aboutUs',
					},
					{
						id:6,
						path:'/contactUs',
					},
				],
			}
		},
		computed: {
			navigation(){
				return this.$store.state.initData.navigation
			},
			styleObj: function () {
				let routerList = [];
				if(this.routerList && this.navigation){
					this.routerList.forEach(item =>{
						this.navigation.forEach(val =>{
							if(val.id == item.id){
								routerList.push(item.path)
							}
						})
					})
				}
				let style = {};
				let nowIndex = routerList.indexOf(this.$route.path);

				if (nowIndex > -1) {
					style = {left: nowIndex * 90 + 45 + 'px'}
				} else {
					style = {display: 'none'}
				}
				return style;
			},
			toPath(){
				return id =>{
					let toPath = ''
					this.routerList.forEach(item =>{
						if(item.id == id){
							toPath = item.path
						}
					})
					return toPath
				}
			}
		},
		methods: {
			// 跳转外部链接
			goWeb(url) {
				if (url) {
					if (url.indexOf('http') == -1) {
						url = 'https://' + url
					}
					window.open(url, '_blank');
				}
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	a {
		color: #666666;
		text-decoration: none;
		font-size: 16px;
		width: 90px;
		text-align: center;
		display: inline-block;

		&.router-link-exact-active {
			color: #22529A;
		}
		&:hover{
			color: #22529A;
		}
	}
	.header-box {
		padding: 12px 0;
		position: sticky;
		top: 0;
		background: #fff;
		z-index: 999;
		border-bottom: 1px solid #e6e6e6;
		
		.head-max{
			width: 1260px;
			display: flex;
			margin: 0 auto;
			align-items: center;
			.login-box{
				margin-left: 20px;
				display: flex;
				align-items: center;
				&::before{
					content: '';
					margin-right: 30px;
					width: 1px;
					height: 14px;
					background: #CCCCCC;
					border-radius: 1px;
				}
				.btn{
					cursor: pointer;
					margin: 0 10px;
					width: 60px;
					height: 26px;
					line-height: 26px;
					text-align: center;
					background: #CCCCCC;
					border-radius: 2px;
					color: #fff;
					>a{
						color: #fff;
						width: auto;
						font-size: 14px;
					}
					&.active{
						background: #22529A;
					}
					&:hover{
						background: #22529A;
					}
				}
			}
		}
		.logo {
			flex: 1;
			text-align: left;
			font-size: 0;
		}
		.logo img {
			height: 60px;
			line-height: 60px;
		}
		.router-box{
			position: relative;
			line-height: 60px;
			height: 60px;
		}
		
		.active-line{
			display: block;
			height: 2px;
			width: 30px;
			background: #22529A;
			content: "";
			position: absolute;
			transition: 0.3s;
			bottom: 8px;
			left: 45px;
			margin-left: -15px;
		}
	}
</style>
