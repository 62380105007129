import Vue from 'vue'

// 按需引入vantUI组件
import {
	DropdownMenu, 
	DropdownItem,
	Swipe, 
	SwipeItem,
	Icon,
	Popup,
	Lazyload,
	Image as VanImage,
	ImagePreview,
	Search,
	Pagination,
	Empty
} from 'vant';

Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(Search);
Vue.use(Pagination);
Vue.use(ImagePreview);
Vue.use(Empty);


