<template>
	<div class="header-box">
		<div class="head-max">
			<img class="logo" src="../assets/logo.png" alt="">
			<div class="flex-1" @click="routerFn">
				<van-icon name="apps-o" size="32" />
			</div>
		</div>

		<!-- 菜单栏 -->
		<van-popup v-model="routerShow" position="right" :style="{ height: '100%' }">
			<div class="router-box">
				<router-link :to="toPath(item.id)" class="router-item" v-for="item in navigation" :key="item.id">{{item.name}}</router-link>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		name: "hHeader",
		data() {
			return {
				routerList: [
					{
						id:1,
						path:'/',
					},
					{
						id:4,
						path:'/platform',
					},
					{
						id:8,
						path:'/ipZone',
					},
					{
						id:7,
						path:'/productCore',
					},
					{
						id:5,
						path:'/aboutUs',
					},
					{
						id:6,
						path:'/contactUs',
					},
				],
				routerShow: false,
			}
		},
		computed:{
			navigation(){
				return this.$store.state.initData.navigation
			},
			toPath(){
				return id =>{
					let toPath = ''
					this.routerList.forEach(item =>{
						if(item.id == id){
							toPath = item.path
						}
					})
					return toPath
				}
			}
		},
		watch: {
			$route() {
				this.routerShow = false;
			}
		},
		methods: {
			routerFn() {
				// 显示右侧菜单栏
				this.routerShow = true;
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.header-box {
		position: sticky;
		top: 0;
		box-shadow: 0 2px 0 #f7f7f7;
		background: #fff;
		z-index: 1000;

		.head-max {
			display: flex;
			align-items: center;
			padding: 0.4rem 0.8rem;
		}

		.logo {
			width: 7rem;
		}

		.flex-1 {
			flex: 1;
			text-align: right;
			color: #666;
		}

		.router-box {
			.router-item {
				display: block;
				height: 2rem;
				line-height: 2rem;
				padding: 0.4rem 0.8rem;
				border-bottom: 1px solid #e5e5e5;
				width: 10rem;
			}

			a {
				color: #666666;
				text-decoration: none;
				font-size: 16px;
				width: 90px;
				text-align: center;
				display: inline-block;

				&.router-link-exact-active {
					color: #22529A;
				}

				&:hover {
					color: #22529A;
				}
			}
		}
	}
</style>
