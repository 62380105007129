<template>
	<div>
		<div class="fiexd-box">
			<div class="show-more-btn" :class="{'show-cls': showMore}">
				<!-- 在线客服 -->
				<div class="item-cls">
					<a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2594716645&site=qq&menu=yes">
						<img class="fiexd-img-cls kf-img" src="../assets/qq.png" alt="">
					</a>
				</div>
				<div class="item-cls" v-for="(item, index) in initData.sus_list" :key="index">
					<img class="fiexd-img-cls" :src="item.sus_img" alt="" @click="showItem(index)">
				</div>
			</div>
			<div class="fiexd-icon" @click="showMore = !showMore">
				<van-icon name="service-o" size="32" />
			</div>
		</div>

		<!-- 菜单栏 -->
		<van-popup v-model="popupShow" :round="true">
			<!-- 悬浮弹窗 -->
			<div class="popup-box">
				<img class="popup-img" :src="currentItem.pop_img" alt="">
				<div class="desc-cls">{{currentItem.pop_word}}</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		computed: {
			...mapState({
				initData: state => state.initData //初始化数据
			})
		},
		data() {
			return {
				popupShow: false,
				showMore: false, // 是否显示联系图标
				currentItem: '' // 当前显示的标签

			}
		},
		methods: {
			showItem(idx) {
				this.currentItem = this.initData.sus_list[idx];
				this.popupShow = true;
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.fiexd-box {
		position: fixed;
		right: 0.6rem;
		bottom: 15%;
		z-index: 999;

		.fiexd-icon {
			background: #fff;
			color: #1989FA;
			border-radius: 50%;
			box-shadow: 0px 0px 6px 0px #1989FA;
			height: 3rem;
			width: 3rem;

			.van-icon-service-o {
				line-height: 3rem;
			}
		}

		.show-more-btn {
			height: 0;
			overflow: hidden;
			transition: all .3s;
		}

		.show-more-btn.show-cls {
			height: 13rem;
		}

		.item-cls {
			position: relative;
			padding: 0.5rem 0;
			text-align: center;
			cursor: pointer;
			border-radius: 50%;

			a {
				text-decoration: none;
				color: #333;
			}
		}

		.fiexd-img-cls {
			height: 3rem;
			width: 3rem;
			border-radius: 50%;
		}

	}
	
	.popup-box {
		padding: 1rem;
		
		.popup-img{
			width: 15rem;
			height: 15rem;
		}
		
		.desc-cls{
			margin-top: 0.6rem;
			font-size: 1rem;
		}
	}
</style>
