export default {
	data() {
		return {
			scrollTop: 0, // 滚动条位置
			offsetTopArr: [], // 元素距离顶部的位置
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		// console.log(this.$route.params.toClass,'toClass')
		if(this.$route.params.toClass){
			this.$nextTick(() => {
				this.getOffsetTop([this.$route.params.toClass],0)
				let oft = document.querySelector(this.$route.params.toClass).offsetTop 
				// console.log(oft)
				window.scrollTo(0,oft)
			})
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		// 获得滚动条高度
		handleScroll() {
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		},
		// 动画显示
		getOffsetTop(arr, reduce = 500) {
			for (let i = 0; i < arr.length; i++) {
				if (document.querySelector(`${arr[i]}`)) {
					const nowTop = document.querySelector(`${arr[i]}`).offsetTop - reduce;
					this.offsetTopArr.push(nowTop);
				}
			}
		}
	},
}
