<template>
	<div v-if="initData.info">
		<h-partner></h-partner>
		<div class="footer-box">
			<!-- 底部联系我们 -->
			<div class="bg-box">
				<div class="foot-max">
					<div class="foot-detail">
						<div class="name-cls">联系方式</div>
						<div class="contact-box">
							<div class="img-text">
								<img src="../assets/phone.png" alt="">
								<span>{{initData.info.mobile}}</span>
							</div>
							<div class="img-text flex-1">
								<img src="../assets/email.png" alt="">
								<span>{{initData.info.email}}</span>
							</div>
							<div class="img-text">
								<img src="../assets/address.png" alt="">
								<span>{{initData.info.address}}</span>
							</div>
							<div class="img-text">
								<img src="../assets/website.png" alt="">
								<span>网址：<a :href="initData.info.website">{{initData.info.website}}</a></span>
							</div>
						</div>
					</div>
					<div class="foot-flex-1">
						<div class="name-cls">官方平台</div>
						<img class="QRcode-cls" :src="initData.info.code_id" alt="">
					</div>
				</div>
			</div>
			<div class="foot-bottom"><a href="http://beian.miit.gov.cn/" target="_blank">{{beiCode}}</a></div>
		</div>
	</div>
</template>

<script>
	import hPartner from './hPartner.vue'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			hPartner
		},
		computed: {
			...mapState({
				initData: state => state.initData //初始化数据
			}),
			beiCode(){
				let host = window.location.host
				if(host.includes('feifeiyu.com')){
					return '闽ICP备09067954号-11'
				}else if(host.includes('entsku.com')){
					return '闽ICP备09067954号-10'
				}else{
					return '闽ICP备09067954号-11'
				}
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.footer-box {
		background: #22529a;
		color: #fff;

		.bg-box {
			padding: 3rem 2rem 1rem;
		}

		.foot-max {
			display: flex;
			margin: 0 auto;
			align-items: center;
			padding-bottom: 2rem;
		}

		.foot-flex-1 {
			flex: 1;
			text-align: right;
		}

		.name-cls {
			font-size: 1rem;
		}

		.QRcode-cls {
			margin-top: 1.6rem;
			height: 5rem;
			width: 5rem;
		}

		.foot-detail {
			text-align: left;
		}

		.contact-box {
			border-top: 1px solid #91a9cd;
			padding-top: 0.6rem;
			margin-top: 0.5rem;
		}

		.img-text {
			padding: 0.1rem 0;

			img {
				vertical-align: middle;
				margin-right: 0.2rem;
				height: 1.2rem;
				width: 1.2rem;
			}

			a {
				color: #fff;
				text-decoration: none;
			}
		}

		.foot-bottom {
			background: #14315c;
			height: 3rem;
			line-height: 3rem;

			a {
				color: #fff;
				text-decoration: none;
			}
		}

		a:hover {
			color: #91a9cd;
		}
	}
</style>
