import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ffyService from './request/ffy.js';
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)
	
const api = new ffyService();

// 公用css
import './style/reset.css'
import './style/public.css'

// 引入动画库
import './style/animate.css'

// 全局混入动画js
import animate from './mixins/animate.js'
Vue.mixin(animate)

// 引入element ui
import '@/utils/elementUi'

// 引入vantui
import '@/utils/vantUi'


// 绑定原型
Vue.prototype.$api = api;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
