<template>
	<div v-if="initData.info">
		<!-- 合作伙伴 -->
		<Partner></Partner>
		<div class="footer-box">
			<!-- 底部联系我们 -->
			<div class="foot-max">
				<div class="foot-link">
					<div class="foot-link-cot">
						<div class="link-title">关于我们</div>
						<div class="link-item">
							<router-link :to="{name:'About',params:{toClass:'.about-us'}}">公司介绍</router-link>
						</div>
						<div class="link-item">
							<router-link :to="{name:'Platform',params:{toClass:'.fx-tab'}}">分销模式</router-link>
						</div>
						<div class="link-item">
							<router-link :to="{name:'About',params:{toClass:'.second-box'}}">品牌故事</router-link>
						</div>
						<div class="link-item">
							<router-link :to="{name:'Platform',params:{toClass:'.fx-tab',tabIndex:1}}">售后条款</router-link>
						</div>
					</div>
					<div class="foot-link-cot">
						<div class="link-title">渠道合作</div>
						<div class="link-item">代理合作</div>
						<div class="link-item">
							<router-link :to="{name:'About',params:{toClass:'.seventh-box'}}">商务伙伴</router-link>
						</div>
					</div>
				</div>
				<div class="foot-detail">
					<div class="name-cls">联系方式</div>
					<div class="contact-box">
						<div class="img-text">
							<img src="../assets/phone.png" alt="">
							<span>{{initData.info.mobile}}</span>
						</div>
						<div class="img-text flex-1">
							<img src="../assets/email.png" alt="">
							<span>{{initData.info.email}}</span>
						</div>
						<div class="img-text">
							<img src="../assets/address.png" alt="">
							<span>{{initData.info.address}}</span>
						</div>
					</div>
					<div class="img-text">
						<img src="../assets/website.png" alt="">
						<span>网址：<a :href="initData.info.website">{{initData.info.website}}</a></span>
					</div>
				</div>
				<div class="foot-flex-1">
					<div class="flex-1-cot">
						<div class="name-cls">官方平台</div>
						<img class="QRcode-cls" :src="initData.info.code_id" alt="">
						<img class="QRcode-cls" :src="initData.info.code_id" alt="">
					</div>
					
				</div>
			</div>
			<div class="foot-bottom"><a href="http://beian.miit.gov.cn/" target="_blank">{{beiCode}}</a></div>
		</div>
	</div>
</template>

<script>
	import Partner from './partner.vue'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Partner
		},
		computed: {
			...mapState({
				initData: state => state.initData //初始化数据
			}),
			beiCode(){
				let host = window.location.host
				if(host.includes('feifeiyu.com')){
					return '闽ICP备09067954号-11'
				}else if(host.includes('entsku.com')){
					return '闽ICP备09067954号-10'
				}else{
					return '闽ICP备09067954号-11'
				}
			},
		},
	}
</script>

<style scoped="scoped" lang="scss">
	.footer-box {
		background: #22529a;
		color: #fff;

		.foot-max {
			width: 1260px;
			display: flex;
			margin: 0 auto;
			align-items: center;
			padding: 60px 0;
		}

		.foot-flex-1 {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			.flex-1-cot{
				text-align: left;
				>img{
					&:nth-child(2){
						margin-right: 10px;
					}
				}
			}
		}

		.name-cls {
			font-size: 16px;
		}

		.QRcode-cls {
			margin-top: 30px;
			height: 80px;
			width: 80px;
		}

		.foot-detail {
			text-align: left;
		}

		.contact-box {
			margin-top: 30px;
			border-bottom: 1px solid #91a9cd;
			padding-bottom: 20px;
			font-size: 12px;
			width: 660px;
			display: flex;
			margin-bottom: 20px;

			.flex-1 {
				flex: 1;
				text-align: center;
			}

		}

		.img-text {
			display: inline-block;

			img {
				vertical-align: middle;
				margin-right: 10px;
				height: 25px;
				width: 25px;
			}
			
			a{
				color: #fff;
				text-decoration: none;
			}
		}

		.foot-bottom {
			background: #14315c;
			height: 60px;
			line-height: 60px;
			
			a{
				color: #fff;
				text-decoration: none;
			}
		}
		
		a:hover{
			color: #91a9cd;
		}
		.foot-link{
			display: flex;
			width: 190px;
			justify-content: space-between;
			margin-right: 70px;
			.link-title{
				font-size: 16px;
				margin-bottom:20px;
			}
			.link-item{
				margin-bottom: 10px;
				cursor: pointer;
				&:hover{
					color: #91a9cd;
				}
				a{
					color: #fff;
					&:hover{
						color: #91a9cd;
					}
				}
			}
		}
	}
</style>
