<template>
	<div>
		<!-- 轮播图 -->
		<van-swipe class="banner-box" :autoplay="3000" width="100%" indicator-color="white">
			<van-swipe-item v-for="(item, idx) in bannerList" :key="idx">
				<img class="img-cls" v-lazy="item.img_id" alt="">
			</van-swipe-item>
		</van-swipe>

		<!-- 合作IP -->
		<div class="cooperation-ip-box">
			<div class="content-cls">
				<div class="main-title ">
					<div class="big-title">合作IP</div>
					<div class="small-title">— Cooperative IP —</div>
				</div>
				<van-swipe class="cooperation-list" :autoplay="3000" width="100%" indicator-color="white">
					<van-swipe-item v-for="(item, idx) in cooperationIpList" :key="idx" class="cooperation-item">
						<div class="cooperation-cls" v-for="img in item" :key="img.id" @click="jump(`/ipProduct/${img.id}`)">
							<van-image class="img-cls" :src="img.img_id" lazy-load />
							<div class="cooperation-name">{{img.name}}</div>
						</div>
					</van-swipe-item>
				</van-swipe>
				<div class="bottom-box">
					<div class="btn-cls" @click="jump('/ipZone')">查看更多</div>
				</div>
			</div>
		</div>

		<!-- 文创 -->
		<div class="cultural-box">
			<div class="content-cls">
				<div class="main-title">
					<div class="big-title">IP文创产品</div>
					<div class="small-title">— IP Cultural and Creative Products —</div>
				</div>
				<div class="cultural-list">
					<van-image class="img-cls" v-for="item in culturalList" :key="item.id" :src="item.img_id" fit="fill" lazy-load
					 @click="jump(`/productDetail/${item.id}`)" />
				</div>
				<div class="btn-cls more-btn" @click="jump('/ipZone')">查看更多</div>
			</div>
		</div>

		<!-- IP项目合作案例 -->
		<div class="case-box">
			<div class="content-cls">
				<div class="main-title">
					<div class="big-title">IP项目合作案例</div>
					<div class="small-title">— IP project cooperation case —</div>
				</div>
				<div class="case-list">
					<div class="case-item" v-for="(item) in caseList" :key="item.id">
						<van-image class="img-cls" :src="item.img_id" fit="fill" lazy-load />
						<div class="text-box two-cls">
							<div class="title-cls">{{item.title}}</div>
							<div class="msg-cls">{{item.introduction}}</div>
							<div class="btn-cls" @click="jump(`/caseDetail/${item.id}`)">点击查看</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 品牌故事 -->
		<div class="brand-box" v-if="brandStory">
			<div class="content-cls">
				<div class="main-title white-cls">
					<div class="big-title">品牌故事</div>
					<div class="small-title">— Xiamen Feifei Fish Brand Story —</div>
				</div>
				<div class="detail-cls">{{brandStory.desc}}</div>
				<div class="bottom-box">
					<div class="btn-cls" @click="jump('/aboutUs')">查看更多</div>
				</div>
			</div>
			<van-image class="img-cls" :src="brandStory.img_id" fit="cover" lazy-load />
		</div>
		
		<!-- 产品分类 -->
		<div class="category-box">
			<div class="main-title">
				<div class="big-title">产品分类</div>
				<div class="small-title">— product category —</div>
			</div>
			<div class="content-cls category-list">
				<div class="category-item" v-for="item in categoryList" :key="item.id" @click="jump('/productCore')">
					<van-image class="img-cls" :src="item.img_id" fit="fill" lazy-load />
					<div class="title-cls">{{item.name}}</div>
					<div class="msg-cls">{{item.desc}}</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				bannerList: [], // 轮播图
				cooperationIpList: [], // 合作IP
				culturalList: [], // IP文创产品
				caseList: [], // 案例列表
				brandStory: null, // 品牌故事
				categoryList: [], // 产品分类
			}
		},
		created() {
			this.setFromStorage();
			// 获取数据
			this.getData();
		},
		methods: {
			setFromStorage(){
				if(this.$route.query.from){
					sessionStorage.setItem('from',this.$route.query.from)
				}else{
					sessionStorage.removeItem('from')
				}
			},
			// 获取详情
			getData() {
				let obj ={
					from:this.$route.query.from
				}
				this.$api.getIndex(obj).then(res => {
					if (res.code == '0000') {
						this.bannerList = res.data.banner;

						// 分割ip数组，3个一组
						let currentArr = [];
						for (let i = 0; i < res.data.cooip.length; i++) {
							currentArr.push(res.data.cooip[i])
							if ((i != 0 && (i + 1) % 2 == 0) || (i == res.data.cooip.length - 1)) {
								this.cooperationIpList.push(currentArr);
								currentArr = [];
							}
						}

						this.culturalList = res.data.productlist.length < 7 ? res.data.productlist : res.data.productlist.splice(0, 6);
						this.caseList = res.data.cooperation_case.splice(0, 2);
						this.brandStory = res.data.brand_story;
						this.categoryList = res.data.goods_cate;

						// 调用混入的动画js计算滚动条
						this.$nextTick(() => {
							let selectArr = [
								'.cooperation-ip-box',
								'.cultural-box',
								'.case-box',
								'.brand-box',
								'.category-box'
							]
							this.getOffsetTop(selectArr)
						})
					}
				})
			},
			// 轮播图跳转
			go(url) {
				if (url) {
					this.$router.push({
						path: url
					})
				}
			},
			// 跳转
			jump(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		font-size: 0;
		height: 15rem;

		.img-cls {
			height: 100%;
			width: 100%;
		}
	}

	.cooperation-ip-box {
		padding: 3rem 0 3rem 0;

		.cooperation-list {
			padding: 3rem 0;

			.cooperation-cls {
				height: 10rem;
				width: 10rem;
				position: relative;
				display: inline-block;
				transition: all 0.3s;
				cursor: pointer;
				border-radius: 2rem;
				overflow: hidden;
				margin-right: 1rem;
			}
			
			.cooperation-cls:nth-child(2n) {
				margin-right: 0;
			}

			.img-cls {
				height: 10rem;
				width: 10rem;
			}

			.cooperation-name {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.3);
				color: #fff;
				text-align: center;
				height: 2.8rem;
				line-height: 2.8rem;
				font-size: 1rem;
			}

			.img-cls:hover {
				transform: scale(1.05);
			}

			.cooperation-item .img-cls:first-child {
				margin-left: 0;
			}
		}

		.bottom-box {
			text-align: center;
		}

		.btn-cls {
			width: 8rem;
			height: 2.4rem;
			background: #22529A;
			border-radius: 2rem;
			line-height: 2.4rem;
			color: #fff;
			display: inline-block;
			cursor: pointer;
			border: 1px solid #22529A;
		}

	}

	.cultural-box {
		background: #fafafa;
		padding: 3rem 0;

		.cultural-list {
			padding-top: 3rem;

			.img-cls {
				height: 10rem;
				width: 10rem;
				border-radius: 1rem;
				overflow: hidden;
				margin: 0 1rem 1rem 0;
				transition: all 0.3s;
				cursor: pointer;
			}

			.img-cls:nth-child(2n) {
				margin-right: 0;
			}
		}

		.more-btn {
			background: #22529A;
			color: #fff;
			width: 8rem;
			height: 2.4rem;
			line-height: 2.4rem;
			border-radius: 2rem;
			display: inline-block;
			cursor: pointer;
		}
	}

	.case-box {
		padding: 3rem 0;

		.case-list {
			padding-top: 3rem;

			.case-item {
				width: 9.2rem;
				display: inline-block;
				vertical-align: top;
				margin-bottom: 1rem;
				box-shadow: 0 0 4px #e6e6e6;
				padding: 1rem 0.6rem;
				border-radius: 0.2rem;
			}

			.case-item:nth-child(2) {
				margin-left: 1rem;
			}

			.case-item:nth-child(3) {
				margin: 0;
			}

			.text-box {
				height: 9rem;
				position: relative;
				text-align: left;
				margin-bottom: 1rem;
			}

			.text-box.two-cls {
				margin-bottom: 0;
				margin-top: 1rem;
			}

			.title-cls {
				font-size: 0.48rem;
				color: #4D4D4D;
				height: 2.4rem;
				margin-bottom: 0.2rem;
			}

			.msg-cls {
				font-size: 0.4rem;
				color: #666;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
			}

			.img-cls {
				height: 6.5rem;
				width: 9rem;
				border-radius: 1rem;
				overflow: hidden;
			}

			.btn-cls {
				width: 100px;
				height: 34px;
				background: #22529A;
				line-height: 34px;
				color: #fff;
				border-radius: 17px;
				display: inline-block;
				text-align: center;
				position: absolute;
				right: 0;
				bottom: 0;
				cursor: pointer;
			}
		}
	}
	
	.brand-box {
		height: 20rem;
		position: relative;
		text-align: center;
		color: #fff;
		font-size: 0;
	
		.content-cls {
			width: 100%;
			position: absolute;
			z-index: 111;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
	
			.bottom-box {
				text-align: center;
			}
	
			.btn-cls {
				width: 8rem;
				height: 2.4rem;
				line-height: 2.4rem;
				border-radius: 2rem;
				background: #FFFFFF;
				color: #333;
				font-size: 12px;
				display: inline-block;
				cursor: pointer;
			}
			
		}
	
		.detail-cls {
			font-size: 0.36rem;
			line-height: 1.4rem;
			width: 90%;
			display: inline-block;
			margin: 1rem 0;
		}
	
		.img-cls {
			height: 100%;
			width: 100%;
		}
	
	}
	
	.brand-box:after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: block;
		content: "";
		background: rgba(0, 0, 0, 0.5);
	}
	
	.category-box {
		padding: 3rem 0;
	
		.category-list {
			margin-top: 2rem;
			text-align: center;
	
			.category-item {
				width: 10rem;
				height: 15rem;
				background: #FFFFFF;
				box-shadow: 0px 0px 12px 0px rgba(34, 82, 154, 0.15);
				border-radius: 6px;
				margin: 0 1rem 1rem 0;
				display: inline-block;
				text-align: left;
				cursor: pointer;
				overflow: hidden;
			}
	
			.category-item:nth-child(2n) {
				margin-right: 0;
			}
			
			.category-item:last-child {
				margin: 0;
			}
				
	
			.img-cls {
				height: 9.2rem;
				width: 10rem;
				transition: all .3s;
			}
	
			.category-item:hover {
				box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);
	
				.img-cls {
					transform: scale(1.05);
				}
			}
	
			.title-cls {
				font-size: 16px;
				line-height: 24px;
				margin: 20px 0 10px 0;
				padding: 0 10px;
			}
	
			.msg-cls {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				font-size: 12px;
				color: #666;
				padding: 0 10px;
			}
		}
	}
	
</style>
