export default {
  // 返回API
  hostApi: hostApi
}

// test 测试版product正式版
// const apiType = '1.0.0'
// const apiType = 'test'
const apiType = 'product'

/**
 *请求的接口
 * @returns {string}
 */
function hostApi () {
  var API_HOST = ''
  switch (apiType) {
    case '1.0.0':
      API_HOST = `http://admin.feifeiyu.com/api.php/`
      break
    case 'test':
      API_HOST = `http://ffy.hy.tmf520.cn/api.php/`
      break
    case 'product':
      API_HOST = `http://admin.feifeiyu.com/api.php/`
      break
  }
  return API_HOST
}
