<template>
	<div id="app">
		<template v-if="showBoolean">
			<header-com></header-com>
			<fiexd-btn></fiexd-btn>
			<router-view />
			<footer-com></footer-com>
		</template>
		<template v-else>
			<h-header></h-header>
			<h-fiexd-btn></h-fiexd-btn>
			<router-view />
			<h-footer></h-footer>
		</template>
	</div>
</template>

<script>
	// PC端
	import headerCom from './components/Header.vue'
	import footerCom from './components/Footer.vue'
	import fiexdBtn from './components/fiexdBtn.vue'
	// 移动端
	import hHeader from './components/hHeader.vue'
	import hFooter from './components/hFooter.vue'
	import hFiexdBtn from './components/hFiexdBtn.vue'

	import {
		mapState
	} from 'vuex';

	export default {
		components: {
			headerCom,
			footerCom,
			fiexdBtn,
			hHeader,
			hFooter,
			hFiexdBtn
		},
		data() {
			return {}
		},
		watch: {
			$route() {
				// 回到顶部
				window.scrollTo(0, 0);
			}
		},
		computed: {
			...mapState({
				showBoolean: state => state.platform, // 当前设备信息
			})
		},
		created() {
			this.getInitData();
			this.getBanner();
		},
		methods: {
			getInitData() {
				this.$api.getInitFoot().then(res => {
					if (res.code == '0000') {
						this.$store.commit('INIT_DATA', res.data);
					}
				})
			},
			getBanner(){
				this.$api.getBannerList().then(res => {
					if (res.code == '0000') {
						// type（类型 1首页2供应链3授权ip4原创ip5联系我们6免费图库7产品中心8关于我们9合作案例）
						let obj = {}
						res.data.forEach(item =>{
							obj[item.type] = [];
						})
						res.data.forEach(item =>{
							obj[item.type].push(item.img_id)
						})
						// console.log(obj,'00000')
						this.$store.commit('SAVEBANNERINFO', obj);
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #333;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		-khtml-user-select: none;
	}

	html {
		font-size: 16px;
	}
	
	@media only screen and (max-width: 321px) {
		html {
			font-size: 14px !important;
		}
	}

	@media only screen and (min-width: 401px) {
		html {
			font-size: 18px !important;
		}
	}

	@media only screen and (min-width: 428px) {
		html {
			font-size: 21.5px !important;
		}
	}

	@media only screen and (min-width: 481px) {
		html {
			font-size: 24px !important;
		}
	}

	@media only screen and (min-width: 569px) {
		html {
			font-size: 28px !important;
		}
	}

	@media only screen and (min-width: 641px) {
		html {
			font-size: 32px !important;
		}
	}
</style>
