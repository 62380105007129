import { render, staticRenderFns } from "./hFooter.vue?vue&type=template&id=4b09a074&scoped=true&"
import script from "./hFooter.vue?vue&type=script&lang=js&"
export * from "./hFooter.vue?vue&type=script&lang=js&"
import style0 from "./hFooter.vue?vue&type=style&index=0&id=4b09a074&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b09a074",
  null
  
)

export default component.exports