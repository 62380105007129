<template>
	<div>
		<el-carousel height="600px" class="banner-box">
			<el-carousel-item v-for="(item, index) in bannerInfo" :key="index">
				<el-image class="img-cls" :src="item" fit="fill" @click="go(item.turn_path)"></el-image>
			</el-carousel-item>
		</el-carousel>

		<!-- 目标用户画像 -->
		<div class="cooperation-ip-box">
			<div class="content-cls">
				<div class="main-title ">
					<div class="big-title">目标用户画像</div>
					<div class="small-title">— 欢迎加入我们一起赚钱！ —</div>
				</div>
				<div class="cooperation-list">
					<div class="cooperation-item" v-for="(item, index) in cooperationIpList" :key="index">
						<el-image class="cooperation-img" :src="item.img" fit="fill" :lazy="true"></el-image>
						<div class="cooperation-name">
							<span>{{item.text}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 热卖品|新品 -->
		<div class="cultural-box">
			<div class="content-cls">
				<div class="main-title">
					<div class="big-title">热卖品|新品</div>
					<div class="small-title">—   紧随市场趋势，每月保持新品上线   —</div>
				</div>
				<div class="cultural-tab">
					<div :class="culturalType == 'hot'?'li active':'li'" @click="changeCulturalType('hot')">热卖品</div>
					<div :class="culturalType == 'new'?'li active':'li'" @click="changeCulturalType('new')">新品</div>
				</div>
				<div class="cultural-list" v-show="culturalType == 'hot'">
					<div class="cultural-item" v-for="item in productHot" :key="item.id">
						<el-image class="cultural-img" :src="item.img_id" fit="fill" :lazy="true"
					 @click="jump(`/productDetail/${item.id}`)"></el-image>
					 	<div class="cultural-name">{{item.title}}</div>
					</div>	
				</div>
				<div class="cultural-list" v-show="culturalType == 'new'">
					<div class="cultural-item" v-for="item in productNew" :key="item.id">
						<el-image class="cultural-img" :src="item.img_id" fit="fill" :lazy="true"
					 @click="jump(`/productDetail/${item.id}`)"></el-image>
					 	<div class="cultural-name">{{item.title}}</div>
					</div>	
				</div>
				<div class="btn-cls more-btn" @click="jump('/productCore')">查看更多</div>
			</div>
		</div>

		<!-- 在线免费图库 -->
		<div class="freeGallery-box">
			<div class="content-cls">
				<div class="main-title">
					<div class="big-title">在线免费图库</div>
					<div class="small-title">—   好图出好物，创意素材溢价高   —</div>
				</div>
				<div class="freeGallery-list">
					<div class="freeGallery-nor-item">
						<el-image v-for="item in gallerynorList" :key="item.id" :src="item.img_id" fit="fill" :lazy="true"></el-image>
					</div>
					<div class="freeGallery-sp-item">
						<el-image v-for="item in galleryspList" :key="item.id" :src="item.img_id" fit="fill" :lazy="true" @click="jump(`/GalleryDetail/${item.id}`)"></el-image>
					</div>
				</div>
			</div>
		</div>

		<!-- 合作案例 -->
		<div class="case-box">
			<div class="content-cls">
				<div class="main-title">
					<div class="big-title">合作案例</div>
					<div class="small-title">— 定制无国界，做全球生意 —</div>
				</div>
				<div class="case-list">
					<swiper v-if="caseList.length > 0" :options="swiperOption" class="mySwiper" ref="mySwiper">
						<swiper-slide v-for="item in caseList" :key="item.id">
							<div class="case-item" @click="jump(`/cooperationcaseList`)">
								<div class="case-main">
									<img class="case-img" :src="item.img_id" />
									<div class="case-name">{{item.title}}</div>
									<div class="case-ide">{{item.country}}</div>
								</div>
								<div class="case-det">
									<p>{{item.introduction}}</p>
								</div>
							</div>		
						</swiper-slide>
						
					</swiper>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
				
			</div>
		</div>

		<!-- 品牌故事 -->
		<!-- <div class="brand-box" v-if="brandStory">
			<div class="content-cls">
				<div class="main-title white-cls">
					<div class="big-title">品牌故事</div>
					<div class="small-title">— Xiamen Feifei Fish Brand Story —</div>
				</div>
				<div class="detail-cls">{{brandStory.desc}}</div>
				<div class="bottom-box">
					<div class="btn-cls" @click="jump('/aboutUs')">查看更多</div>
				</div>
			</div>
			<el-image class="img-cls" :src="brandStory.img_id" fit="fill" :lazy="true"></el-image>
		</div> -->

		<!-- 产品分类 -->
		<!-- <div class="category-box">
			<div class="main-title">
				<div class="big-title">产品分类</div>
				<div class="small-title">— product category —</div>
			</div>
			<div class="content-cls category-list">
				<div class="category-item" v-for="item in categoryList" :key="item.id" @click="jump('/productCore')">
					<el-image class="img-cls" :src="item.img_id" fit="fill" :lazy="true"></el-image>
					<div class="title-cls">{{item.name}}</div>
					<div class="msg-cls">{{item.desc}}</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>

	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import 'swiper/css/swiper.css'
	import {mapState} from 'vuex';
	export default {
		data() {
			return {
				cooperationIpList: [ //目标用户头像 固定图片文案
					{
						img:require('@/assets/targetUser/img_1.png'),
						text:'个人创业者'
					},
					{
						img:require('@/assets/targetUser/img_2.png'),
						text:'电商卖家团队'
					},
					{
						img:require('@/assets/targetUser/img_3.png'),
						text:'社媒流量主'
					},
					{
						img:require('@/assets/targetUser/img_4.png'),
						text:'IP文创品牌方'
					},
					{
						img:require('@/assets/targetUser/img_5.png'),
						text:'线下画室'
					},
					{
						img:require('@/assets/targetUser/img_6.png'),
						text:'国内外设计师'
					},
				],
				culturalList: [], 
				productHot:[],//热卖品
				productNew:[],//// 新品
				caseList: [], // 合作案例
				brandStory: null, // 品牌故事
				categoryList: [], // 产品分类
				gallerynorList:[],//在线免费图库 普通图片
				galleryspList:[],//在线免费图库 主题图片
				culturalType:'hot',
				swiperOption: {
					effect:'coverflow',
					slidesPerView: 4,
					centeredSlides: true,
					coverflowEffect: {
						rotate: 0,
						stretch: 20,
						depth: 80,
						modifier: 2,
						slideShadows : false
					},
					// 设置前进后退按钮
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					// 设置轮播可循环
					loop: true,
				}
			}
		},
		computed: {
			...mapState({
				bannerInfo: state => state.bannerInfo[1], // banner图
			}),
			mySwiper(){
				return this.$refs['mySwiper'].swiper
			}
		},
		created() {
			// 获取数据
			this.setFromStorage();
			this.getData();
			this.getGalleryList();
			this.getCooperationcaseList()
		},
		mounted(){
		},
		components: {
			swiper,
    		swiperSlide
		},
		methods: {
			setFromStorage(){
				if(this.$route.query.from){
					sessionStorage.setItem('from',this.$route.query.from)
				}else{
					sessionStorage.removeItem('from')
				}
			},
			getCooperationcaseList(){
				//获取合作案例
				let obj = {
					list_rows:7
				}
				this.$api.getCooperationcaseList(obj).then(res => {
					if (res.code == '0000') {
						this.caseList = res.data;
					}
				})
			},
			// 获取详情
			getData() {
				let obj ={
					from:this.$route.query.from
				}
				this.$api.getIndex(obj).then(res => {
					if (res.code == '0000') {
						this.culturalList = res.data.productlist.length < 16 ? res.data.productlist : res.data.productlist.splice(0, 16);
						this.brandStory = res.data.brand_story;
						this.categoryList = res.data.goods_cate;
						this.productHot = res.data.product_hot;
						this.productNew = res.data.product_new;

						// 调用混入的动画js计算滚动条
						this.$nextTick(() => {
							let selectArr = [
								'.cooperation-ip-box',
								'.cultural-box',
								'.case-box',
								'.brand-box',
								'.category-box'
							]
							this.getOffsetTop(selectArr,0)
							// window.scrollTo(0,this.offsetTopArr[0])
						})
					}
				})
			},
			getGalleryList(){
				//获取在线图库
				let obj1 = {
					list_rows:6,
					type:1,
				}
				this.$api.getGalleryList(obj1).then(res => {
					if (res.code == '0000') {
						this.gallerynorList = res.data.data;
					}
				})
				let obj2 = {
					list_rows:2,
					type:2,
				}
				this.$api.getGalleryList(obj2).then(res => {
					if (res.code == '0000') {
						this.galleryspList = res.data.data;
					}
				})
			},
			// 轮播图跳转
			go(url) {
				if (url) {
					this.$router.push({
						path: url
					})
				}
			},
			// 跳转
			jump(url) {
				this.$router.push({
					path: url
				})
			},
			changeCulturalType(type){
				this.culturalType = type;
			},
		}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		font-size: 0;

		.img-cls {
			height: 100%;
			width: 100%;
		}
	}

	.cooperation-ip-box {
		padding-top:90px;

		.cooperation-list {
			padding-top: 70px;
			display: flex;
			flex-wrap: wrap;
			.cooperation-item{
				width: 360px;
				margin: 0 90px 80px 0;
				height: 360px;
				transition: all 0.3s;
				box-shadow: 0px 0px 15px 0px rgba(13,4,8,0.1500);
				border-radius: 60px;
				&:hover{
					transform: scale(1.05);
				}
				&:nth-child(3n){
					margin-right: 0;
				}
				.cooperation-img{
					display: block;
					width: 100%;
					height: 260px;
					border-radius: 60px;
				}
				.cooperation-name{
					position: relative;
					height: 100px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #22529A;
					font-size: 30px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					>span{
						position: relative;
						z-index: 1;
					}
					&::before{
						position: absolute;
						content:'';
						bottom: 35px;
						left: 50%;
						transform: translateX(-50%);
						width: 220px;
						height: 10px;
						background: #E0EBFB;
						border-radius: 5px;
					}
				}
			}
		}
	}

	.cultural-box {
		background: #fafafa;
		padding: 90px 0 70px 0;
		.cultural-tab{
			margin-top: 20px;
			display: flex;
			justify-content: center;
			height: 63px;
			border-bottom: 2px solid #F1F1F1;
			.li{
				position: relative;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 175px;			
				font-size: 18px;
				color: #333333;
				&.active{
					color:#22529A;
					&::before{
						position: absolute;
						content: '';
						width: 175px;
						height: 8px;
						bottom: -2px;
						background: url('~@/assets/tab_active_icon.png');
					}
				}
			}
		}
		.cultural-list {
			padding-top: 70px;
			display: flex;
			flex-wrap: wrap;
			.cultural-item{
				width: 270px;
				margin: 0 60px 50px 0;
				cursor: pointer;
				transition: all 0.3s;
				&:nth-child(4n){
					margin-right: 0;
				}
				&:hover{
					transform: scale(1.05);
				}
				.cultural-img{
					display: block;
					height: 270px;
					border-radius: 60px;
				}
				.cultural-name{
					margin-top: 19px;
					font-size: 18px;
					color: #333;
					text-align: center;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			
		}

		.more-btn {
			background: #22529A;
			color: #fff;
			width: 150px;
			height: 44px;
			line-height: 44px;
			border-radius: 22px;
			display: inline-block;
			cursor: pointer;
		}
	}

	.freeGallery-box{
		padding: 89px 0 62px 0;
		.content-cls{
			width: 1280px;
		}
		.freeGallery-list{
			margin-top: 47px;
			display: flex;
			justify-content: center;
			align-items: center;
			.freeGallery-nor-item{
				display: flex;
				flex-wrap: wrap;
				width: 690px;
				margin-right: 28px;
				>.el-image{
					margin: 20px 0;
					width: 230px;
					height: 230px;
				}
			}
			.freeGallery-sp-item{
				display: flex;
				flex-direction: column;
				>.el-image{
					margin: 8px 0;
					width: 554px;
					height: 254px;
					cursor: pointer;
				}
			}
		}
	}

	.case-box {
		padding: 90px 0;
		background: #fafafa;
		.case-list {
			position: relative;
			padding-top: 70px;
			.swiper-slide{
				display: flex;
				align-items: center;
				height: 380px;
			}
			.swiper-button-prev{
				left: -50px;
				top: 58%;
				width: 57px;
				height: 57px;
				&::after{
					content: '';
					width: 57px;
					height: 57px;
					background: url('~@/assets/icon_lf_nor.png');
				}
				&:hover{
					&::after{
						background: url('~@/assets/icon_lf_pre.png');
					}
				}
			}
			.swiper-button-next{
				right: -50px;
				top: 58%;
				width: 57px;
				height: 57px;
				&::after{
					content: '';
					width: 57px;
					height: 57px;
					background: url('~@/assets/icon_rg_nor.png');
				}
				&:hover{
					&::after{
						background: url('~@/assets/icon_rg_pre.png');
					}
				}
			}
			.swiper-container{
				width: 1150px;
			}
			.case-item{
				width: 300px;
				height: 360px;
				border-radius: 20px;
				box-shadow: 0px 0px 20px 0px rgba(34,82,154,0.2000);
				background: #FFFFFF;
				.case-main{
					padding: 30px 0 13px 0;
					border-bottom: 1px solid #CCCCCC;
					text-align: center;
					.case-img{
						margin: 0 auto 8px;
						width: 130px;
						height: 130px;
						border-radius: 50%;
					}
					.case-name{
						color: #333;
						font-size: 34px;
						margin-bottom: 8px;
					}
					.case-ide{
						color: #4D4D4D;
					}
				}
				.case-det{
					padding: 15px 10px;
					>p{
						font-size: 16px;
						line-height: 24px;
						display: -webkit-box;
						-webkit-line-clamp:3;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
					}
				}
			}
		}
	}

	.brand-box {
		height: 900px;
		position: relative;
		text-align: center;
		color: #fff;
		font-size: 0;

		.content-cls {
			width: 1260px;
			position: absolute;
			z-index: 111;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			.bottom-box {
				text-align: center;
			}

			.btn-cls {
				width: 150px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 22px;
				line-height: 44px;
				color: #333;
				font-size: 12px;
				display: inline-block;
				cursor: pointer;
			}

			.btn-cls:hover {
				background: #22529A !important;
				color: #fff !important;
			}
		}

		.detail-cls {
			font-size: 16px;
			line-height: 32px;
			width: 811px;
			display: inline-block;
			margin: 70px 0;
		}

		.img-cls {
			height: 100%;
			width: 100%;
		}

	}

	.brand-box:after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: block;
		content: "";
		background: rgba(0, 0, 0, 0.5);
	}

	.category-box {
		padding: 90px 0 70px;

		.category-list {
			margin-top: 70px;
			text-align: left;

			.category-item {
				width: 270px;
				height: 360px;
				background: #FFFFFF;
				box-shadow: 0px 0px 12px 0px rgba(34, 82, 154, 0.15);
				border-radius: 6px;
				margin-right: 60px;
				margin-bottom: 60px;
				display: inline-block;
				text-align: left;
				cursor: pointer;
				overflow: hidden;
			}

			.category-item:nth-child(4n) {
				margin-right: 0;
			}

			.img-cls {
				height: 250px;
				width: 270px;
				transition: all .3s;
			}

			.category-item:hover {
				box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);

				.img-cls {
					transform: scale(1.05);
				}
			}

			.title-cls {
				font-size: 16px;
				line-height: 24px;
				margin: 20px 0 10px 0;
				padding: 0 10px;
			}

			.msg-cls {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				font-size: 12px;
				color: #666;
				padding: 0 10px;
			}
		}
	}
</style>
