import store from '../store/index.js'
import Index from "../views/Index.vue";
import hIndex from "../h5/index.vue"


// PC端路由
const pcRoutes = [{
		path: "/",
		name: "Index",
		component: Index,
	},
	// 路由懒加载
	{
		path: "/caseDetail/:id",
		name: "CaseDetail",
		component: () =>
			import( /* webpackChunkName: "CaseDetail" */ "../views/CaseDetail.vue"),
	},
	{
		path: "/platform",
		name: "Platform",
		component: () =>
			import( /* webpackChunkName: "Platform" */ "../views/Platform.vue"),
	},
	{
		path: "/ipZone",
		name: "IpZone",
		component: () =>
			import( /* webpackChunkName: "ipZone" */ "../views/IpZone.vue"),
	},
	{
		path: "/ipProduct/:id",
		name: "ipProduct",
		component: () =>
			import( /* webpackChunkName: "ipProduct" */ "../views/IpProduct.vue"),
	},
	{
		path: "/productCore",
		name: "Product",
		component: () =>
			import( /* webpackChunkName: "product" */ "../views/Product.vue"),
	},
	{
		path: "/productDetail/:id",
		name: "productDetail",
		component: () =>
			import( /* webpackChunkName: "productDetail" */ "../views/ProductDetail.vue"),
	},
	{
		path: "/aboutUs",
		name: "About",
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/contactUs",
		name: "Contact",
		component: () =>
			import( /* webpackChunkName: "contact" */ "../views/Contact.vue"),
	},
	{
		path: "/galleryDetail/:id",
		name: "GalleryDetail",
		component: () =>
			import( /* webpackChunkName: "GalleryDetail" */ "../views/GalleryDetail.vue"),
	},
	{
		path: "/cooperationcaseList",
		name: "CooperationcaseList",
		component: () =>
			import( /* webpackChunkName: "cooperationcaseList" */ "../views/CooperationcaseList.vue"),
	},
];


// 移动端路由
const hRoutes = [{
		path: "/",
		name: "hIndex",
		component: hIndex,
	}, {
		path: "/caseDetail/:id",
		name: "CaseDetail",
		component: () =>
			import( /* webpackChunkName: "CaseDetail" */ "../h5/CaseDetail.vue"),
	}, {
		path: "/platform",
		name: "Platform",
		component: () =>
			import( /* webpackChunkName: "Platform" */ "../h5/Platform.vue"),
	},
	{
		path: "/ipZone",
		name: "IpZone",
		component: () =>
			import( /* webpackChunkName: "ipZone" */ "../h5/IpZone.vue"),
	}, {
		path: "/ipProduct/:id",
		name: "ipProduct",
		component: () =>
			import( /* webpackChunkName: "ipProduct" */ "../h5/IpProduct.vue"),
	},
	{
		path: "/productCore",
		name: "Product",
		component: () =>
			import( /* webpackChunkName: "product" */ "../h5/Product.vue"),
	},
	{
		path: "/productDetail/:id",
		name: "productDetail",
		component: () =>
			import( /* webpackChunkName: "productDetail" */ "../h5/ProductDetail.vue"),
	},
	{
		path: "/aboutUs",
		name: "About",
		component: () =>
			import( /* webpackChunkName: "about" */ "../h5/About.vue"),
	},
	{
		path: "/contactUs",
		name: "Contact",
		component: () =>
			import( /* webpackChunkName: "contact" */ "../h5/Contact.vue"),
	},
]


let routes = showBl() ? pcRoutes : hRoutes;

// 输入路由列表
export default routes;


// 判断设备信息 true为PC，false为移动端
function showBl() {
	var browser = {
		versions: function() {
			let u = navigator.userAgent;
			return { //移动终端浏览器版本信息 
				trident: u.indexOf('Trident') > -1, //IE内核
				presto: u.indexOf('Presto') > -1, //opera内核
				webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
				gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
				mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
				ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
				android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
				iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
				iPad: u.indexOf('iPad') > -1, //是否iPad  
				webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
				weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
				qq: u.match(/\sQQ/i) == " qq" //是否QQ
			};
		}(),
		language: (navigator.browserLanguage || navigator.language).toLowerCase()
	}

	if (browser.versions.mobile || browser.versions.ios || browser.versions.android ||
		browser.versions.iPhone || browser.versions.iPad) { //h5
		store.commit('PLATFORM', false);
		return false;
	} else {
		store.commit('PLATFORM', true);
		return true;
	}
}
