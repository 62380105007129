import _http from './axios.js'

export default class ffyService {
	/**
	 * @Description:获取首页数据
	 * @author xsq
	 * @param 
	 */
	getIndex(data) {
		const url = `home/index`
		return _http.post(url,data)
	}
	
	/**
	 * @Description:获取案例详情
	 * @author xsq
	 * @param data
	 */
	getCaseDetail(data) {
		const url = `Cooperationcase/getCooDetail`
		return _http.post(url, data)
	}
	
	/**
	 * @Description:获取售后服务
	 * @author xsq
	 * @param 
	 */
	getSalesPolicy() {
		const url = `Cooperationcase/getSalesPolicy`
		return _http.post(url)
	}
	
	/**
	 * @Description:获取IP专区
	 * @author xsq
	 * @param data
	 */
	getIpZone(data) {
		const url = `product/getIpPage`
		return _http.post(url, data);
	}
	
	/**
	 * @Description:获取IP专区分类及轮播图
	 * @author xsq
	 * @param data
	 */
	getIpCate(data) {
		const url = `product/ipcateList`
		return _http.post(url, data);
	}	
	
	/**
	 * @Description:获取IP专区产品列表
	 * @author xsq
	 * @param data
	 */
	getIpProductList(data) {
		const url = `product/getIpProductList`
		return _http.post(url, data);
	}
	
	/**
	 * @Description:获取产品中心分类
	 * @author xsq
	 * @param 
	 */
	getCate() {
		const url = `product/cateList`
		return _http.get(url)
	}
	
	/**
	 * @Description:获取产品列表商品
	 * @author xsq
	 * @param data
	 */
	getProductList(data) {
		const url = `product/getProductList`
		return _http.post(url, data);
	}

	/**
	 * @Description:获取产品详情
	 * @author xsq
	 * @param data
	 */
	 getProductDetail(data) {
		const url = `product/getProductDetail`
		return _http.post(url, data);
	}

	/**
	 * @Description:获取在线图库列表
	 * @author xsq
	 * @param data
	 */
	 getGalleryList(data) {
		const url = `gallery/getGalleryList`
		return _http.post(url, data);
	}
	/**
	 * @Description:获取在线图库详情
	 * @author xsq
	 * @param data
	 */
	 getGalleryDetail(data) {
		const url = `gallery/getGalleryDetail`
		return _http.post(url, data);
	}

	/**
	 * @Description:获取合作案例列表
	 * @author xsq
	 * @param data
	 */
	 getCooperationcaseList(data) {
		const url = `cooperationcase/getCooperationcaseList`
		return _http.post(url, data);
	}
	/**
	 * @Description:获取联系我们
	 * @author xsq
	 * @param data
	 */
	 getContactwayList(data) {
		const url = `Contactway/getContactwayList`
		return _http.post(url, data);
	}
	
	/**
	 * @Description:获取底部数据
	 * @author xsq
	 * @param 
	 */
	getInitFoot() {
		const url = `home/getPublic`
		return _http.post(url)
	}
	/**
	 * @Description:获取banner列表
	 * @author xsq
	 * @param 
	 */
	 getBannerList() {
		const url = `banner/getBannerList`
		return _http.post(url)
	}
	
	/**
	 * @Description:获取关于我们
	 * @author xsq
	 * @param data
	 */
	
	submitContact(data) {
		const url = `Cooperationcase/setDemand`
		return _http.post(url, data);
	}
}
