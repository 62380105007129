<template>
	<div class="bg-cls">
		<div class="main-title">
			<div class="big-title">多平台销售</div>
			<div class="small-title">—   飞飞鱼合作伙伴   —</div>
		</div>
		<!-- 伙伴logo -->
		<div class="partner-box">
			<div class="partner-list move">
				<div class="partner-item" v-for="(item, index) in initData.partner" :key="index" @click="goWeb(item.turn_url)">
					<img class="partner-img" :src="item.img_id" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {
				current: 0, // 当前位置
			}
		},
		computed: {
			...mapState({
				initData: state => state.initData, //初始化数据
			}),
		},
		methods: {
			// 跳转外部链接
			goWeb(url) {
				if (url) {
					if (url.indexOf('http') == -1) {
						url = 'https://' + url
					}
					window.open(url, '_blank');
				}
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.bg-cls {
		padding: 50px 0 35px;
	}

	.partner-box {
		width: 1360px;
		margin: 60px auto 0;

		.partner-list {
			display: flex;
			flex-wrap: wrap;
		}
		.partner-item {
			margin: 0 31px 15px 31px;
			cursor: pointer;
		}

		.partner-img {
			width: 158px;
			height: 60px;
			object-fit:cover;
			transition: all .3s;
		}
		
		.partner-img:hover{
			transform: scale(1.05);
		}
	}
	
	.move{
	    transition: all .3s; //设置过渡动画
	}
</style>
