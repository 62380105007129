<template>
	<div class="fiexd-box">
		<!-- 在线客服 -->
		<div class="item-cls" v-for="(item, index) in initData.sus_list" :key="index">
			<img class="fiexd-img-cls" :src="item.sus_img" alt="">
			<div class="name-cls">{{item.sus_word}}</div>
			<!-- 悬浮弹窗 -->
			<div class="popup-box">
				<img class="popup-img" :src="item.pop_img" alt="">
				<div class="desc-cls">{{item.pop_word}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		computed: {
			...mapState({
				initData: state => state.initData //初始化数据
			})
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.fiexd-box {
		position: fixed;
		right: 0;
		top: 50%;
		z-index: 999;
		transform: translateY(-50%);
		width: 100px;
		min-height: 100px;
		background: #fff;
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

		.item-cls {
			position: relative;
			padding: 10px 0;
			text-align: center;
			border-top: 1px solid #afafaf;
			cursor: pointer;
			
			a{
				text-decoration: none;
				color: #333;
			}
		}

		.fiexd-img-cls {
			height: 50px;
			width: 50px;
			margin-bottom: 8px;
		}

		.popup-box {
			display: none;
			position: absolute;
			left: -144px;
			top: 50%;
			transform: translateY(-50%);
			padding: 10px;
			background: #fff;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
			transition: all .3s;

			.popup-img {
				height: 110px;
				width: 110px;
			}

			.desc-cls {
				margin-top: 10px;
			}
		}

		.popup-box:after {
			content: "";
			display: block;
			position: absolute;
			right: -10px;
			bottom: 20%;
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-left: 10px solid #fff;
			border-bottom: 10px solid transparent;
			filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .2));
		}

		.item-cls:first-child {
			border-top: none;
		}

		.item-cls:hover .popup-box {
			display: block;
		}
		
		.kf-img{
			margin-bottom: 6px;
		}
		
		.kf-box{
			color: #22529A;
		}

	}
</style>
